import React from "react";
import s from './BgLine1.scss';
import { useEffect, useRef } from "react";
import gsap from "gsap";


export const BgLine1 = () => {

    const lineRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(
        lineRef.current,
        {
            drawSVG: '0%',
        },
        {
            duration: 2.5,
            delay: 1.5,
            drawSVG: '100%',
            ease: 'linear',
        }
        );
    }, [])
    return (
        <div id="hero__line" className={s.hero__line}>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" fill="none">
                <path ref={lineRef}
                xmlns="http://www.w3.org/2000/svg" opacity="0.5" d="M543.492 0V623.103C543.492 733.56 453.949 823.103 343.492 823.103H0" stroke="#1B52AD" stroke-width="2"/>
            </svg>
        </div>
    )
}