import React, { useEffect, useRef } from 'react';
import s from './BgLine2.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll"

export const BgLine2 = ({ pageThemeLight=false, style }) => {

  const lineContainerRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
      gsap.from(lineRef.current, {
        drawSVG: '0%',
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top center",
          end: "bottom center"
        },
      });
  }, []);

  return (
    <div className={s.section__draw} ref={lineContainerRef} style={style}>
      <svg className={s('path__draw__1', {pageThemeLight})}
        preserveAspectRatio='none'
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          ref={lineRef} 
          xmlns="http://www.w3.org/2000/svg"
          opacity="0.5"
          d="M0 1H510C620.457 1 710 90.5431 710 201V251C710 361.457 799.543 451 910 451H2064.5"
          stroke="#1B52AD"
          stroke-width="2"/>
      </svg>
    </div>
  );
};